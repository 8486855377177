import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import { HashLink } from 'react-router-hash-link';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>Entscheidungen</Typography>


          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Bundesfinanzhofs</Typography>
            <Typography variant='body1' className='sectionText'>
            Die steuerliche Behandlung niedrig verzinster Darlehen zwischen nahestehenden Personen als gemischte Schenkung und die Berücksichtigung marktüblicher Zinssätze.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/bundesfinanzhof-darlehen">Weiterlesen</HashLink>
          </Box>

          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Finanzgerichts (FG) Schleswig</Typography>
            <Typography variant='body1' className='sectionText'>
              Ratenzahlungsvereinbarungen innerhalb der Familie unterliegen nicht der Einkommensteuer.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/fg-schleswig">Weiterlesen</HashLink>
          </Box>

          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Oberlandesgericht (OLG) Schleswig</Typography>
            <Typography variant='body1' className='sectionText'>
            Ein Geschäftsführer haftet der Gesellschaft auf Schadenersatz, wenn er das Gesellschaftsvermögen nicht versichert und versichert hält.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/olg-schleswig">Weiterlesen</HashLink>
          </Box>

          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Bundesfinanzhofs</Typography>
            <Typography variant='body1' className='sectionText'>
            Der Bundesfinanzhof hält Vereinbarungen über gesellschafterbezogene Kapitalrücklagen für zivil- und steuerrechtlich zulässig.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/bundesfinanzhof">Weiterlesen</HashLink>
          </Box>

          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Finanzgerichts (FG) Niedersachsen</Typography>
            <Typography variant='body1' className='sectionText'>
            Nach Ansicht des Finanzgerichts Niedersachsen fällt Spekulationsteuer bei einer Immobilienschenkung mit Gegenleistungen dann nicht an, wenn die historischen Anschaffungskosten höher als die Gegenleistung ist.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/fg-niedersachsen">Weiterlesen</HashLink>
          </Box>


          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Finanzgericht (FG) Mecklenburg-Vorpommern</Typography>
            <Typography variant='body1' className='sectionText'>
            Das Finanzgericht Mecklenburg-Vorpommern entschied, dass ohne explizite Vereinbarung im Kaufvertrag aufstehende Bäume als wesentliche Grundstücksbestandteile gelten und daher grunderwerbsteuerpflichtig sind.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/fg-mecklenburg-vorpommern">Weiterlesen</HashLink>
          </Box>


          <Box>
            <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung des Sozialgerichts (SG) Neubrandenburg</Typography>
            <Typography variant='body1' className='sectionText'>
            Nach Ansicht des SG Neubrandenburg ist ein mit 50% als Gesellschafter beteiligter Geschäftsführer i.S.d. § 7 SGB IV abhängig beschäftigt, sofern die Satzung keine besonderen Regelungen vorsieht.
            </Typography>
            <HashLink className='btnSecondary hash' smooth to="/SG-Neubrandenburg">Weiterlesen</HashLink>
          </Box>






        </Container>
      </main >
    </div >
  );
}

export default Imprint;
import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>Bundesfinanzhof</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Bundesfinanzhof</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>31.07.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>II R 20/22</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>DB 2024, 3081</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>ja</Typography>
              </Box>

            </Box>

          </Box>

          

          <Typography variant='h2' className='sectionTitle3 marginTop'>Leitsätze | BFH - II R 20/22</Typography>
          <Typography variant='body1' className='sectionText'>
          1. Die Gewährung eines nicht marktüblich verzinsten Darlehens ist als gemischte Schenkung zu versteuern.</Typography>
          <Typography variant='body1' className='sectionText'>
          2. Bei der Bemessung des Zinsvorteils kann der in § 15 Abs. 1 des BewG festgelegte Zinssatz von 5,5 % nicht herangezogen werden, wenn ein niedrigerer marktüblicher Wert für vergleichbare Darlehen feststeht.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | BFH - II R 20/22</Typography>
          <Typography variant='body1' className='sectionText'>
          Zwei Geschwister vereinbarten einen Darlehensvertrag miteinander, bei dem die minderjährige Schwester, vertreten durch einen Ergänzungspfleger, ihrem Bruder eine Darlehenssumme in Höhe von 1.875.768,05 € zur Verfügung stellte. Das Darlehen wurde auf unbestimmte Zeit gewährt und konnte erstmals nach vier Jahren mit einer Frist von zwölf Monaten gekündigt werden. Es wurde ein Zins von 1 % vereinbart. Der Darlehensvertrag wurde durch das Familiengericht genehmigt und war damit wirksam.</Typography>
          <Typography variant='body1' className='sectionText'>Das Finanzamt betrachtete diesen Vorgang als gemischte Schenkung, da die Vereinbarung eines Zinses von 1% stark von den marktüblichen Kreditkonditionen abwich. Es ermittelte anhand der Statistiken der Deutschen Bundesbank im Zeitraum zwischen Vertragsschluss und erstmaliger Kündigungsmöglichkeit einen durchschnittlichen Effektivzinssatz zwischen 2,67 % und 2,81 %. Das Finanzamt sah sich jedoch daran gehindert, diese Werte heranzuziehen, da die vereinbarten Darlehenskonditionen hinsichtlich Laufzeit, Fälligkeit und Tilgung von marktüblichen Darlehensbedingungen abwichen. Das Finanzamt griff deshalb auf die Hilfsvorschrift des § 15 Abs. 1 BewG zurück, die von einem Zinssatz in Höhe von 5,5 % ausgeht. In Höhe der Differenz zwischen dem tatsächlich vereinbarten Zinssatz von 1 % und dem Hilfswert des § 15 Abs. 1 BewG (5,5 %) betrachtete es eine freigebige Zuwendung (konkret in Höhe von 4,5%). Den jährlichen Wert der Schenkung ermittelte es dabei mit 84.409,56 (1.875.768,05 € x 4,5 %). Da es sich um Nutzungen und Leistungen von ungewisser Dauer handelte, bewertete das Finanzamt den Nutzungsvorteil gemäß § 13 Abs. 2 Halbsatz 2 BewG mit dem 9,3 fachen des Jahreswerts, also mit 785.008,91 €, und setzte entsprechend Schenkungsteuer in Höhe von 229.500 EUR fest.</Typography>
          <Typography variant='body1' className='sectionText'>Die Beteiligten wandten ein, keiner der Beteiligten habe eine Schenkung gewollt. Folglich fehle es an einem Bewusstsein über die Schenkung und damit an einem entsprechenden Schenkungswillen, was jeweils Voraussetzung der Besteuerung durch § 7 Abs. 1 ErbStG sei. 
          Die Klage blieb in der ersten Instanz vor dem FG Mecklenburg-Vorpommern (v. 27.04.2022 – 3 K 273/20 – DStRE 2023, 800) ohne Erfolg. Das FG erkannte zwar an, dass man im fraglichen Zeitraum einen Zinssatz von durchschnittlich bis zu 2,81% grundsätzlich habe erhalten können. Es sah aber ebenso wie das Finanzamt keine Anhaltspunkte dafür, dass auch der Kläger (der Bruder) selbst eine solche Finanzierung zu diesen Konditionen im konkreten Fall hätte erhalten können. Entsprechende, auf den Zeitpunkt des Vertragsschlusses datierende Finanzierungszusagen konnte er nicht vorlegen.</Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | BFH - II R 20/22</Typography>
          <Typography variant='body1' className='sectionText'>Private Darlehensvereinbarungen sind in der notariellen Praxis nicht selten. Kann oder will der Erwerber einer Immobilie etwa zur Finanzierung des Kaufpreises kein Darlehen bei einer Bank aufnehmen, kommt auch eine Finanzierung durch den Verkäufer in Betracht, etwa in Gestalt eines Verkäuferdarlehens oder Kaufpreisstundung. Der Erwerber zahlt in diesem Fall den Kaufpreis nicht sofort, sondern über einen längeren Zeitraum in Raten ab. Aus der üblichen Drei-Personen-Beziehung (Veräußerer-Erwerber -Bank) wird eine Zwei-Personen-Beziehung, bei der der Veräußerer die Rolle der Bank übernimmt.</Typography>
          <Typography variant='body1' className='sectionText'>Die Frage ist in allen Fällen, wie hoch sollte bzw. muss der Zinssatz vereinbart werden, um steuerlich unangenehme Folgen zu vermeiden. Fest steht, ein zinsloses Darlehen ist in jedem Fall eine freigebige Zuwendung nach § 7 Abs. 1 Nr. 1 ErbStG und unterliegt damit auch der Schenkungsteuer (BFH v. 27.10.2010 – II R 37/09, DStRE 2011, 163 und v. 27.11.2013 – II R 25/12, ZEV 2014, 267). § 15 Abs. 1 BewG fingiert in diesen Fällen grundsätzlich einen jährlichen Zinssatz in Höhe von 5,5%. Dieser Zinswert ist auch dann anzuwenden, wenn zwar ein Zins unterhalb des Schwellenwertes von 5,5% vereinbart wird, dieser aber nicht marktüblich ist. Nur bei Nachweis der Marktüblichkeit des vereinbarten Zinses steht i.S.d. § 15 Abs. 1 BewG ein „anderer Wert fest“ und die Heranziehung des gesetzlichen Zinssatzes ist gehemmt.</Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | BFH - II R 20/22</Typography>
          <Typography variant='body1' className='sectionText'>Der BFH gab dem Kläger teilweise recht, hob die erstinstanzliche Entscheidung des FG Mecklenburg-Vorpommern auf und änderte den Steuerbescheid schließlich selbst ab.</Typography>
          <Typography variant='body1' className='sectionText'>Das Finanzgericht Mecklenburg-Vorpommern ist nach Ansicht des BFH zunächst aber zutreffend davon ausgegangen, dass in der zinsverbilligten Darlehensgewährung eine freigebige Zuwendung nach § 7 Abs. 1 Nr. 1 ErbStG zu sehen ist. Per definitionem setzt eine freigebige Zuwendung in objektiver Hinsicht voraus, dass die Leistung zu einer Bereicherung des Bedachten auf Kosten des Zuwendenden führt und die Zuwendung (objektiv) unentgeltlich ist, und in subjektiver Hinsicht den Willen des Zuwendenden zur Freigebigkeit (BFH v. 16.09.2020 - II R 24/18, BFHE 272, 87, BStBl II 2021, 621, Rz 13). </Typography>
          <Typography variant='body1' className='sectionText'>In objektiver Hinsicht war die verbilligte Kapitalüberlassung zweifellos eine (teilweise) Schenkung, da der Empfänger eines niedrig verzinsten Darlehens durch die Gewährung des Rechts, das als Darlehen überlassene Kapital zu einem niedrigeren Zinssatz als dem marktüblichen zu nutzen, eine Vermögensmehrung erfährt. Bei marktüblicher Alternativfinanzierung hätte er eine höhere Gegenleistung zahlen müssen, d.h. er „spart“ in diesem Fall Kapital. Ein vergleichbares Darlehen zu einem vergleichbar niedrigen Zinssatz von 1 % hätte der Darlehensnehmer am Markt nicht aufnehmen können. Umkehrt besteht die Minderung des Vermögens des Zuwendenden darin, dass er auf einen Ertrag verzichtet, den er bei verkehrsüblichem Verhalten gezogen hätte (BFH v. 17.04.1991 - II R 119/88, BFHE 164, 130). Dabei ist es unerheblich, dass zivilrechtlich in der bloßen vorübergehenden Gebrauchsüberlassung einer Sache in der Regel keine das Vermögen mindernde Zuwendung liegt, wie sie für eine Schenkung gemäß § 516 Abs. 1 des Bürgerlichen Gesetzbuchs erforderlich ist (BFH v. 27.11.2013 - II R 25/12, BFH/NV 2014, 537, Rz 13, zu einem zinslosen Darlehen). Gegenstand der Zuwendung ist der kapitalisierte Nutzungsvorteil.</Typography>
          <Typography variant='body1' className='sectionText'>Das vom Kläger vorgebrachte Argument, es habe allen Beteiligten am Schenkungsbewusstsein gefehlt, ließ der BFH nicht gelten und sah auch dieses Tatbestandsmerkmal als erfüllt an. Zur Erfüllung des subjektiven Tatbestands einer freigebigen Zuwendung bedarf es zwar des Bewusstseins des Zuwendenden, die Leistung ohne Verpflichtung und ohne rechtlichen Zusammenhang mit einer Gegenleistung oder einem Gemeinschaftszweck zu erbringen. Für die zutreffende Vorstellung des Zuwendenden von dem Begriff der (Un-)Entgeltlichkeit genügt es aber, wenn er dessen rechtlich-sozialen Bedeutungsgehalt laienhaft zutreffend erfasst (BFH v. 01.09.2021 - II R 40/19, BFHE 275, 248 Rz 11). Bei einem Zinssatz von 1 % und einer grundsätzlich unbestimmten Laufzeit muss nach Ansicht des BFH allen bewusst gewesen sein, dass das Darlehen teilweise unentgeltlich gewährt wurde. Nicht ausschlaggebend für die Erfüllung des subjektiven Tatbestands der freigebigen Zuwendung ist, ob der Ergänzungspfleger mit Abschluss des Darlehens zivilrechtlich ordnungsgemäß gehandelt hat, oder ob die Beteiligten davon ausgingen, dass eine alternative und zugleich sichere Anlage des Geldes zu keinem höheren Zinssatz möglich gewesen wäre.</Typography>
          <Typography variant='body1' className='sectionText'>Der BFH folgte jedoch dem Finanzgericht Mecklenburg-Vorpommern nicht in seinen Feststellungen zur Höhe der jährlichen Schenkung bzw. unentgeltlichen zugewandten Zinsdifferenz in Höhe von 4,5%. Fälschlicherweise ist das FG davon ausgegangen, dass der gesetzliche Zinssatz des § 15 Abs. 1 BewG in Höhe von 5,5% anzuwenden sei. Es ist zwar zutreffend, dass bei niedrig verzinsten Darlehen die für die schenkungsteuerrechtliche Berechnung maßgebliche Zinsdifferenz aus dem Unterschied zwischen dem vereinbarten Zinssatz und dem sich aus § 15 Abs. 1 BewG ergebenden Zinssatz zu bilden ist. Durch die Formulierung des zweiten Halbsatzes in § 15 Abs. 1 BewG „wenn kein anderer Wert feststeht“ hat der Gesetzgeber aber zum Ausdruck gebracht, dass grundsätzlich von dem gemeinen Wert der Nutzung in Höhe von 5,5 % auszugehen ist, ein anderer Wert aber dann herangezogen werden kann, wenn dieser feststeht.</Typography>
          <Typography variant='body1' className='sectionText'>Wenn das Finanzgericht einen aus vergleichbaren Darlehen abgeleiteten marktüblichen Zinssatz ausdrücklich feststellt, dann steht damit aber auch ein „anderer Wert“ im Sinne des § 15 Abs. 1 BewG fest. Der Steuerpflichtige muss in diesem Fall nicht noch zusätzlich nachweisen, dass er eine Finanzierung zu den durchschnittlichen Zinssätzen auch realistisch habe bekommen kann. § 15 Abs. 1 BewG verlangt – anders als etwa § 198 BewG - nicht, dass der Steuerpflichtige den „anderen Wert“ nachweisen muss; er muss lediglich ‚feststehen‘. Steht der Zinssatz aber fest, dann kann der in § 15 Abs. 1 BewG normierte gesetzliche Zinssatz gerade nicht herangezogen werden bzw. ist es widersprüchlich, diesen heranzuziehen.</Typography>
          <Typography variant='body1' className='sectionText'>Der BFH änderte den Steuerbescheid sodann ab und berechnete den Wert der freigebigen Zuwendung und die festzusetzende Schenkungsteuer selbst. Für die Ermittlung der schenkungsteuerrechtlichen Bereicherung ist nach § 12 Abs. 1 ErbStG i.V.m. § 13 Abs. 1 BewG von einem Jahreswert des Nutzungsvorteils in Höhe von 1,81 % der Darlehenssumme in Höhe von 1.875.768,05 € auszugehen, also einem Jahreswert in Höhe von 33.951,40 €. Dieser ist gemäß § 13 Abs. 2 BewG mit dem Faktor 9,3 zu multiplizieren, sodass sich ein Wert der Bereicherung für die freigebige Zuwendung in Höhe von 315.748,02 € ergibt. Von dem Steuerwert der freigebigen Zuwendung in Höhe von 315.748 € ist der Freibetrag für einen Erwerb zwischen Schwester und Bruder nach § 16 Abs. 1 Nr. 5 i.V.m. § 15 Abs. 1 Steuerklasse II Nr. 2 ErbStG in Höhe von 20.000 € in Abzug zu bringen, sodass sich ein steuerpflichtiger Erwerb in Höhe von 295.748 € ergibt. Dieser ist gemäß § 10 Abs. 1 Satz 6 ErbStG auf volle 100 € nach unten abzurunden. Der in Ansatz zu bringende steuerpflichtige Erwerb beträgt daher 295.700 €. Der anzuwendende Steuersatz in Steuerklasse II beläuft sich gemäß § 19 Abs. 1 ErbStG bei einem Erwerb bis einschließlich 300.000 € auf 20 %. Die festzusetzende Schenkungsteuer beträgt daher 59.140 €.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxis- und Gestaltungshinweise | BFH - II R 20/22</Typography>
          <Typography variant='body1' className='sectionText'>Die Entscheidung des BFH ist sehr zu begrüßen und sorgt für Entspannung bei den Urkundsbeteiligten, die sich des Schenkungscharakters der Zinslosigkeit nach dem Hinweis des Notars bewusst sind und es gerade vermeiden wollen, erbschaftsteuerliche Risiken einzugehen. Bisher musste man stets davon ausgehen, dass bei kleinsten Abweichungen vom marktüblichen Zins mit der Heranziehung des gesetzlichen Zinssatzes von 5,5% eine drakonische Strafe droht und in einem erheblichen Maße Schenkungsteuer anfällt. Das führte nicht selten dazu, dass die Beteiligten von der Gestaltung gänzlich Abstand nahmen oder einen im Zweifel höheren Zins vereinbarten (und damit das Kapital bzw. dessen Nutzungsmöglichkeit zu teuer kauften). Mit dieser Entscheidung ist das Risiko erheblich eingegrenzt worden. Eine Schenkung erfolgt also nur in der Höhe, in der eine Schenkung auch tatsächlich vorliegt; und nur in diesem Umfang ist auch Schenkungsteuer zu zahlen. </Typography>
          <Typography variant='body1' className='sectionText'>Die Entscheidung wird dazu führen, dass auch der Notar mit einem Verweis auf die Zinsstatistiken der Deutschen Bundesbank mutiger auf die Frage der Marktüblichkeit antworten und die Drohkulisse abbauen kann. Er sollte den Beteiligten zur Vermeidung langwieriger Verfahren vor dem Finanzgericht dennoch dazu raten, sich im Vorfeld mehrere individualisierte bzw. personalisierte Kreditangebote einzuholen und die vertraglichen Kreditbedingungen daran – insbesondere auch hinsichtlich der Sicherheiten (Einräumung eines Sicherungsgrundpfandrechtes!) – auszurichten. Es kann sinnvoll sein, einige Sätze zur Marktüblichkeit des Zinssatzes in die Urkunde mitaufzunehmen und die Angebote als Anlage zur Urkunde zu nehmen. Sollte das Finanzamt dennoch bei eigener Analyse die Marküblichkeit verneinen, wird es Farbe bekennen und mitteilen müssen, was denn noch als marktüblich gewertet werden kann. Dann aber steht auch ein anderer Zins fest. Das schenkungsteuerliche ist damit Risiko jedenfalls überschaubar.</Typography>

        </Container>

      </main >
    </div >
  );
}

export default Imprint;
import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>Bundesfinanzhof</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Bundesfinanzhof</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>19.06.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>II R 41/21</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>juris</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>ja</Typography>
              </Box>

            </Box>

          </Box>
Bundesfinanzhof

          

          <Typography variant='h2' className='sectionTitle3 marginTop'>Leit- und Orientierungssätze | Bundesfinanzhof - II R 41/21</Typography>
          <Typography variant='body1' className='sectionText'>
          1. Es ist zivilrechtlich zulässig und auch steuerrechtlich anzuerkennen, wenn eine von den Beteiligungsquoten abweichende Zuordnung der Kapitalrücklage gesellschafterbezogen vorgenommen wird.</Typography>
          <Typography variant='body1' className='sectionText'>
          2. Eine solche Zuordnung muss eine satzungsmäßige Grundlage haben. Hierfür genügt es, wenn die Satzung eine Öffnungsklausel enthält, wonach die Gesellschafter Gewinne gem. §§ 29 Abs. 3 S. 2, 72 S. 2 GmbHG auch abweichend von der quotalen Beteiligung verteilen können (sog. disquotale Gewinnverteilung). </Typography>
          <Typography variant='body1' className='sectionText'>
          3. Haben Gesellschafter einer GmbH wirksam vereinbart, dass Leistungen in die Kapitalrücklage gesellschafterbezogen zugeordnet werden, wird jedoch die Kapitalrücklage im Zusammenhang mit einer Kapitalerhöhung abweichend hiervon allen Gesellschaftern entsprechend ihren Beteiligungsquoten zugerechnet, kann der Verzicht auf einen angemessenen Wertausgleich durch den Gesellschafter, der die Leistungen erbracht hat, eine freigebige Zuwendung zugunsten der Mitgesellschafter darstellen.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | Bundesfinanzhof - II R 41/21</Typography>
          <Typography variant='body1' className='sectionText'>
          Drei Personen (Vater V und Söhne A und B) errichteten eine GmbH mit einem Stammkapital von 27.000 EUR, an der sie zu je einem Drittel (9.000 EUR bzw. je 33,3 %) beteiligt waren. Die Satzung ordnete eine quotale Gewinnverteilung nach dem Grundsatz des § 29 Abs. 3 S. 1 GmbHG an, sofern nicht eine andere Gewinnverteilung unter Zustimmung jedes benachteiligten Gesellschafters beschlossen wid. Entsprechendes galt für die Zuweisung und Auflösung der Kapitalrücklagen.</Typography>
          <Typography variant='body1' className='sectionText'>
          Es wurde später folgender Gesellschafterbeschluss gefasst:</Typography>
          <Typography variant='body1' className='sectionText'>
          "Die Gesellschaft soll bislang im Privatvermögen der Gesellschafter gehaltenes Kapitalvermögen zusammenfassen und einheitlich anlegen. Die Gesellschafter werden zu diesem Zwecke aus ihrem Privatvermögen Geld- und Wertpapiervermögen einbringen, ggfs. auch andere Vermögenswerte. Die zur Nutzung eingebrachten Vermögenswerte werden vereinbarungsgemäß den Kapitalrücklagen zugeführt, daneben werden für jeden Gesellschafter Verrechnungskonten geführt, auf die die Gewinnanteile der Gesellschafter, Entnahmen aus den Kapitalrücklagen und Einlagen zunächst verbucht werden. Zwischen den Parteien besteht Einigkeit darüber, dass jeder Gesellschafter über seinen Teil der Rücklagen frei verfügen kann und insbesondere bei disquotalen Einlagen jeder Gesellschafter Rechtsinhaber und Eigentümer seines Anteils der Kapitalrücklagen bleibt, <b>die Kapitalrücklagen also nicht im Verhältnis der Beteiligungen zu je 1/3 den Gesellschaftern zugerechnet werden.</b>"</Typography>

          <Typography variant='body1' className='sectionText'>
          Verbrachte mehrere Bar- und Sachleistungen an die GmbH, die in die Kapitalrücklage der GmbH als "Kapitalrücklage V" eingebucht wurden. Auf diese Weise wurde der Kapitalrücklage der GmbH insgesamt ein Betrag in Höhe von 4,95 Mio. € zugeführt.</Typography>

          <Typography variant='body1' className='sectionText'>
          In einer weiteren Gesellschafterversammlung beschlossen die Gesellschafter, das Stammkapital von 27.000 EUR um 527.500 EUR auf 554.500 EUR zu erhöhen. Hierzu wurden zwei Anteile in Höhe von je 263.750 EUR an die Söhne A und B ausgegeben. Der Vater verzichtete auf eine Teilnahme an der Kapitalerhöhung bzw. auf sein Bezugsrecht. Auf die neuen Anteile wurden von A und B Sacheinlagen in Höhe von 4.704.857,02 EUR (jeweils 2.352.428,51 EUR) geleistet. Der Überwert (d.h. der den Wert von 263.750 EUR überschreitende Wert der Einlagen) in Höhe von 2.088.678,51 (Einbringungswert abzgl. Nennbetrag übernommener Anteile) wurde (als Agio bzw. sonstige Zuzahlung) in die Kapitalrücklage eingestellt.</Typography>
          <Typography variant='body1' className='sectionText'>
          Nach der Kapitalerhöhung ergaben sich folgende Beteiligungsquoten</Typography>


          <table><tbody><tr><td >Gesellschafter</td><td>Prozentuale Beteiligung</td><td >Veränderung in Prozent</td></tr><tr><td>V</td><td>1,6</td><td>- 31,8</td></tr><tr><td>A</td><td >49,2</td><td>+ 15,9</td></tr><tr><td>B</td><td>49,2</td><td>+ 15,9</td></tr><tr><td>Gesamt</td><td >100</td><td>0</td></tr></tbody></table>


          <Typography variant='body1' className='sectionText'>
          In der Kapitalrücklage der Gesellschaft befanden sich nun folgende Einzahlung</Typography>

          <table><tbody><tr><td>V</td><td>A</td><td>B</td><td>Gesamt</td></tr><tr><td>4.950.000 EUR</td><td>2.088.678 EUR</td><td>2.088.678 EUR</td><td>9.127.356 EUR</td></tr></tbody></table>


          <Typography variant='body1' className='sectionText'>
          Die Gesellschafter vereinbarten als Folge der Kapitalerhöhung, die dadurch entstandene Verwässerung des V bzw. seiner Beteiligung auszugleichen. Dieser sollte eine lebenslängliche Rente erhalten. Bei der Berechnung des Ausgleichsbetrages bzw. des Wertverlustes gingen die Gesellschafter von einer Beteiligung am gesamten Eigenkapital und damit auch an der Kapitalrücklage in Höhe von 4.950.000 EUR – entgegen ihres eigenen Beschlusses über die individuelle Zuordnung von Einlageleistung – von je einem Drittel aus, d.h. in Höhe von 1.650.000 EUR statt 4.950.000 EUR.</Typography>

          <Typography variant='body1' className='sectionText'>
          Das Finanzamt vertrat die Ansicht, der Wertverlust habe nicht ausgehend von einer Beteiligung in Höhe von einem Drittel an der Kapitalrücklage berechnet werden dürfen, sondern vielmehr von einer Beteiligung in Höhe von 100% (d.h. in Höhe von 4.950.000 EUR). Die Bemessungsgrundlage war damit viel zu niedrig angesetzt. Der Wertverlust betrage daher nicht 31,8% (1,6% - 33,3 %), sondern 98,4% (1,6% -100%).</Typography>

          <Typography variant='body1' className='sectionText'>
          Die Nichtteilnahme an der Kapitalerhöhung nebst Anpassung (Absenkung) der anteiligen Beteiligung an der Kapitalrücklage (nicht mehr gesellschafterbezogen, sondern „normal“ entsprechend quotaler Beteiligung am Stammkapital, vgl. § 29 Abs. 3 S. 1 GmbHG) stelle damit eine freigiebige Zuwendung i.S.d. § 7 Abs. 1 ErbStG dar, weil dadurch den Gesellschafter Werte zugeflossen sind, die nicht durch wertadäquate Gegenleistungen ausgeglichen wurden.</Typography>




          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | Bundesfinanzhof - II R 41/21</Typography>
          <Typography variant='body1' className='sectionText'>
          Die zentrale Frage des Sachverhalts lautet: Kann ein Gesellschafter in einer GmbH abweichend von dem in §§ 29 Abs. 3 S. 1, 72 S. 1 GmbHG enthaltenen Grundsatz der quotalen Beteiligung am Eigenkapital der Gesellschaft entsprechend §§ 29 Abs. 3 S. 2, 72 S. 2 GmbHG auch wirtschaftlich individuell am Wert eingebrachter Wirtschaftsgüter beteiligt sein?</Typography>
          <Typography variant='body1' className='sectionText'>
          Ist das nicht möglich – wie von dem gegen die Festsetzung von Schenkungsteuer klagenden Gesellschafter vertreten – wäre der V von Beginn an immer nur mit 33,3% an den von ihm eingebrachten Vermögenswerten (insgesamt 4.950.000 EUR) beteiligt gewesen, hätte also bei Einbringung bereits einen entsprechenden Wertverlust von 66,6% erlitten (d.h. in Höhe von jeweils 1.650.000 EUR bz. Insgesamt 3.300.000 EUR). Hierin liegt zwar auch eine Schenkung, die nach § 7 Abs. 8 S. 1 ErbStG (Werterhöhung durch Einbringung) steuerbar ist. Zum Einbringungszeit (2006-2010) existierte die Vorschrift allerdings noch nicht. § 7 Abs. 8 ErbStG galt erst ab dem Jahr 2011. Die Einbringung mit mittelbarer Werterhöhung bei den Söhnen (A und B) wäre also zum damaligen Zeitpunkt sogar steuerfrei gewesen.</Typography>
          <Typography variant='body1' className='sectionText'>
          Der nachfolgende Wertverlust hätte dann richtigerweise auf Grundlage einer Beteiligung von „nur“ 33,3,% berechnet werden können. Nur am Rande sei erwähnt: Unter Geltung des § 7 Abs. 8 S. 1 ErbStG wäre eine Einbringung – wie von den Beteiligten vorgenommen – nur dann steuerneutral, wenn sie wirtschaftlich, d.h. individuell nur dem Einbringenden zugutekommt (sofern diese Vorgehensweise zulässig ist).</Typography>
          <Typography variant='body1' className='sectionText'>
          In einer Personengesellschaft (insbesondere einer Kommanditgesellschaft) ist eine solche individuelle Zuordnung von Einlageleistungen problemlos möglich. Dort müssen individuelle Einlageleistungen eines Gesellschafters nicht zwingend in der gesamthänderisch gebundenen Rücklage gebucht werden, sondern können auch einem Gesellschafter individuell auf seinem variablen (zumeist) <b>Kapitalkonto II</b> genannten Verrechnungskonto gutgeschrieben werden. Das ist deshalb möglich, weil das Personengesellschaftsrecht im Grundsatz von einer variablen Beteiligung der Gesellschafter am Eigenkapital der Gesellschaft ausgeht (§ 120 Abs. 2 HGB) und sogar eine 0%-Beteiligung (Gesellschafter ohne Kapitalanteil, insbesondere eine GmbH als persönlich haftender Gesellschafter einer GmbH & Co.KG) erlaubt.</Typography>
          <Typography variant='body1' className='sectionText'>
          Das Kapitalgesellschaftsrecht ist hier restriktiver. Gesellschafter müssen zwingend eine die Haftungsmasse der Gesellschaft erhöhende Einlageleistung erbringen (Grundsatz der realen Kapitalaufbringung) und können nicht zu null beteiligt sein. Jeder Gesellschafter hält zwingend einen Anteil (insb. Aktie, Geschäftsanteil). Entsprechend seines Anteils hat er Anspruch auf Teilhabe an der Gesellschaft bzw. ihrer Willensbildung (insbesondere festes Stimmrecht, § 47 Abs. 2 GmbHG) und an ihrem Kapital (fester Anspruch auf Gewinn, Abfindung und Liquidationserlös - §§ 29 Abs. 3 s. 1, 72 S. 1 GmbHG). Die Satzung kann hier Öffnungsklauseln vorsehen (etwa Gewinnvorrecht, Thesaurierungsgebote etc.). Hierbei handelt es sich jedoch nicht bloß um Bilanzpositionen, sondern bereits um verfestigte bzw. vorhandene Wirtschaftsgüter (Forderungen), die Ergebnis der Umwandlung bzw. Umsetzung von Eigenkapital oder einzelner Positionen (Feststellung des Jahresabschluss, Vermögensverteilung etc.) sind. Die Frage besteht nun darin, ob abweichende Regelungen auch schon auf rein bilanzieller Ebene ansetzen können und eine individuelle Zuordnung von Bilanzpositionen - § 266 Abs. 3 Teil A HGB du § 272 Abs. 2 HGB – möglich ist.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | Bundesfinanzhof - II R 41/21</Typography>
          <Typography variant='body1' className='sectionText'>
          Der Bundesfinanzhof sieht – zum Leidwesen der Gesellschafter – eine von den Beteiligungsquoten abweichende Zuordnung der Kapitalrücklage als zivil- und steuerrechtlich zulässig an.</Typography>


          <p class="MuiTypography-root MuiTypography-body1 sectionText css-1nuk18e"><b>Tz. 21:</b> Die Kapitalrücklage ist zwar Bestandteil des Eigenkapitals der Gesellschaft und dieses steht allein der Gesellschaft und nicht dem Gesellschafter zu. Insbesondere erwirbt der Gesellschafter im Fall einer Einlage in das Gesellschaftsvermögen nicht ein selbständiges Wirtschaftsgut "Beteiligung an der Kapitalrücklage". Die Einzahlung in die Kapitalrücklage ist vielmehr in rechtlicher Hinsicht aufzuspalten in die Zuführung von Kapital zum Vermögen der Gesellschaft (Einlage) und die anschließende Einstellung des zugeführten Betrags in die Kapitalrücklage. Nur Erstere betrifft das Verhältnis zwischen Gesellschafter und Gesellschaft; die spätere Einstellung in die Rücklage ist ein rein gesellschaftsinterner Vorgang, der die Stellung des Gesellschafters gegenüber der Gesellschaft nicht berührt. Deshalb entsteht auch bei Zuführung des Kapitals zu einer Kapitalrücklage nicht eine Berechtigung des Gesellschafters speziell in Bezug auf diese Rücklage, sondern die Einlage verstärkt lediglich die aus der Beteiligung erwachsende Gesellschafterstellung.</p>


          <p class="MuiTypography-root MuiTypography-body1 sectionText css-1nuk18e"><b>Tz. 22:</b> Die Zuordnung der Kapitalrücklage zum Eigenkapital der Gesellschaft schließt es (…) jedoch nicht aus, dass Leistungen eines Gesellschafters in die Kapitalrücklage in entsprechender Anwendung von § 29 Abs. 3 Satz 2, § 72 Satz 2 des Gesetzes betreffend die Gesellschaften mit beschränkter Haftung (GmbHG) in der Weise gesellschafterbezogen zugeordnet werden können, dass etwa im Fall der Liquidation oder der Auflösung der Kapitalrücklage die geleisteten Beträge nur den Gesellschaftern zustehen sollen, die die Leistung ursprünglich erbracht haben, sodass die übrigen Gesellschafter nicht über ihre Beteiligung von der eingelegten Leistung profitieren. Dies gilt jedenfalls dann, wenn eine von den Beteiligungsverhältnissen abweichende Zuordnung der Kapitalrücklage nach der Satzung der GmbH möglich ist und die Gesellschafter wirksam einen entsprechenden Beschluss fassen. Liegen diese Voraussetzungen vor, ist eine zivilrechtlich wirksam zustande gekommene gesellschafterbezogene Zuordnung der Kapitalrücklage grundsätzlich auch in steuerrechtlicher Hinsicht anzuerkennen (so auch R E 7.5 Abs. 11 Satz 14 der Erbschaftsteuer-Richtlinien 2019).</p>

          <p class="MuiTypography-root MuiTypography-body1 sectionText css-1nuk18e"><b>Tz. 23:</b> Eine gesellschaftsrechtlich zulässige und auch in steuerrechtlicher Hinsicht anzuerkennende Vereinbarung entsprechender disquotaler Rückzahlungsansprüche in Bezug auf die Kapitalrücklage kann dazu führen, dass ein späterer Verzicht auf eine derartige Forderung im Verhältnis der Gesellschafter untereinander einen schenkungsteuerbaren Vorgang nach § 7 Abs. 1 Nr. 1 ErbStG auslöst. (…)</p>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxishinweis | Bundesfinanzhof - II R 41/21</Typography>
          <Typography variant='body1' className='sectionText'>
          Die Entscheidung des Bundesfinanzhofs ist richtungsweisend und eröffnet der Gestaltungspraxis ganz neue Wege und Optionen.</Typography>
          <Typography variant='body1' className='sectionText'>
          Der BFH erlaubt damit im Ergebnis eine Art „Kapitalkonto II“ im Kapitalgesellschaftsrecht. Rechtstechnisch setzt er dies durch eine Art Gewinnvorrecht bzw. Vorrecht auf den Liquidationserlös um. Im Rahmen der Abfindung bei Ausscheiden der Gesellschaft wird daraus ein den Abfindungsbetrag erhöhender Zahlungsanspruch.</Typography>
          <Typography variant='body1' className='sectionText'>
          Der im Sachverhalt dargestellte erste Teil würde unter Geltung des § 7 Abs. 8 S. 1 ErbStG nunmehr Erbschaftsteuer auslösen. Die Einbringung eines Wertes von 4.950.000 EUR in eine GmbH mit Drittelbeteiligung aller Gesellschafter würde (ohne individuelle Kapitalrücklagenzuweisung) zweifellos eine Schenkung von je 1.650.000 EUR an die beiden Mitgesellschafter bedeuten. Dieses Ergebnis lässt sich nur dadurch vermeiden, wenn A und B ebenso jeweils 4.950.000 EUR in die Kapitalrücklage einzahlen, sodass Wertverschiebungen nicht entstehen können. Alternativ hätte der A die Einbringung mit einer Kapitalerhöhung verbinden müssen und das Stammkapital von 27.000 EUR um 4.950.000 EUR auf 4.977.000 EUR unter Ausgabe nur an ihn zu gewährender Anteile im Nennbetrag in Höhe von (insgesamt) 4.950.000 EUR erhöhen müssen. Der vom BFH nun aufgezeigte Lösungsweg der gesonderten Kapitalrücklagenzuweisung löst dieses Problem auf einfache Weise. Es ist aus schenkungsteuerlicher Perspektive keine Kapitalerhöhung mehr notwendig, da die Mitgesellschafter an dem Einbringungswert wirtschaftlich nicht partizipieren können. Gleichwohl kann sich bei Einbringung von Betriebsvermögen ein solches Bedürfnis noch aus umwandlungsteuerlicher Sicht ergeben (insb. §§ 20, 21 UmwStG).</Typography>
          <Typography variant='body1' className='sectionText'>
          Aus Sicht des BFH sind die formellen Anforderungen an einen entsprechenden Beschluss nicht allzu hoch. Für die zivil- und steuerrechtliche Zulässigkeit ist allein entscheidend, dass der Beschluss auf einer satzungsmäßigen Grundlage beruht. Dies ist jedenfalls dann der Fall, wenn die Satzung bei der Gewinnverteilung einer entsprechende Öffnungsklausel vorsieht, wonach der Gewinn zwar grundsätzlich quotal auszuschütten ist, die Gesellschafter aber auch eine abweichende Gewinnverteilung beschließen können. Ein solcher Beschluss ist dann auch nicht beurkundungsbedürftig, sofern nicht Immobilien oder Geschäftsanteile eingebracht werden. Aus Gründung der Nachweisbarkeit gegenüber der Finanzverwaltung ist aber dringend zu einer Beurkundung zu raten.</Typography>

        </Container>

      </main >
    </div >
  );
}

export default Imprint;